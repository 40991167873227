<template>
  <div v-if="!loading">
    <section class="cart-section" v-if="cartsection">
      <div v-if="getCart.length != 0">
        <p class="title">PANIER</p>
        <table class="center concert-table">
          <tbody>
            <tr v-for="(price, id) in getCart" :key="id">
              <th class="case">
                {{
                  price.retrieved_product.name
                    ? price.retrieved_product.name
                    : "NaN"
                }}
                <span v-if="price.nickname"> - {{ price.nickname }}</span>
              </th>

              <td class="case">{{ price.unit_amount / 100 }} €</td>
              <td class="case">
                <button @click="removeItem(price)">
                  x
                </button>
              </td>
            </tr>
            <tr class="total">
              <th>TOTAL</th>
              <th>{{ getPrice }} €</th>
            </tr>
          </tbody>
        </table>
        <button class="validate-cart" @click="ValidateCart">
          VALIDER MON PANIER
        </button>
      </div>
      <div v-else>
        Panier vide
      </div>
    </section>
    <section class="checkout-section" v-else>
      <form>
        <input v-model="metadata.firstname" placeholder="Nom" />
        <input v-model="metadata.lastname" placeholder="Prénom" />
        <input v-model="metadata.address" placeholder="Addresse" />
        <input v-model="metadata.postal" placeholder="Code postal" />
        <input v-model="metadata.city" placeholder="Ville" />
        <input v-model="metadata.email" placeholder="Adresse email" />
        <input
          v-model="metadata.phone_number"
          placeholder="Numéro de téléphone"
        />
        <hr />
        <div>
          <input
            v-model="metadata.retrait"
            type="checkbox"
            class="check"
            @click="changeTo('retrait')"
          />
          Point relai:
          <a
            href="https://www.facebook.com/legarageangers/"
            target="_blank"
            @click="changeTo('delivery')"
            >Le garage Angers</a
          >
        </div>
        <div>
          <input
            v-model="metadata.delivery"
            type="checkbox"
            class="check"
            @click="changeTo('delivery')"
          />
          livraison ({{ shippingTax }} €)
        </div>
        <button @click="ValidateCheckout" type="button">Payer</button>
      </form>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Vue from "vue";

const isValidEmail = require("is-valid-email");

export default {
  data() {
    return {
      cartsection: false,
      loading: true,
      shippingTax: 0,
      metadata: {
        firstname: "",
        lastname: "",
        address: "",
        postal: "",
        city: "",
        email: "",
        phone_number: "",
        delivery: false,
        retrait: true,
      },
    };
  },
  computed: {
    ...mapGetters({
      getCart: "cartModule/getCart",
    }),

    getPrice() {
      let price = 0;

      this.getCart.forEach((element) => {
        price += element.unit_amount;
      });

      return price / 100;
    },

    totalWeight() {
      let cart = this.getCart;
      let totalWeight = 0;

      cart.forEach((elt) => {
        if (elt.retrieved_product.metadata.weight)
          totalWeight += parseInt(elt.retrieved_product.metadata.weight);
      });

      return totalWeight;
    },
  },
  methods: {
    ...mapActions({
      remove: "cartModule/remove",
    }),
    removeItem(item) {
      let removedName = item.retrieved_product.name;
      let removedPrice = item.unit_amount / 100;
      let id = item.id;
      console.log(id, removedPrice, removedName);
      this.remove(id);
      this.$gtag.event("remove_from_cart", {
        item_id: id,
        item_name: removedName,
        price: removedPrice,
      });
    },
    changeTo(name) {
      if (name == "retrait") {
        this.metadata.delivery = false;
        this.metadata.retrait = true;
      } else {
        this.metadata.delivery = true;
        this.metadata.retrait = false;
      }
    },
    ValidateCart() {
      this.cartsection = false;
    },
    ValidateCheckout() {
      let cart = this.getCart;
      let lineItems = [];

      let item_list_id = [];
      let item_list_name = [];

      cart.forEach((elt) => {
        lineItems.push({
          description: elt.retrieved_product.name + " - " + elt.nickname,
          price: elt.id,
          quantity: 1,
        });

        item_list_id.push(elt.retrieved_product.id);
        item_list_name.push(elt.retrieved_product.name + " - " + elt.nickname);
      });

      if (this.metadata.delivery) {
        lineItems.push({
          price_data: {
            currency: "eur",
            product_data: {
              name: "Livraison",
            },
            unit_amount: this.shippingTax * 100,
          },
          quantity: 1,
        });
      }

      if (this.metadata.firstname == "") {
        Vue.notify({
          group: "toast",
          type: "error",
          title: "Veuillez remplir le formulaire",
        });
      } else if (this.metadata.lastname == "") {
        Vue.notify({
          group: "toast",
          type: "error",
          title: "Veuillez remplir le formulaire",
        });
      } else if (this.metadata.address == "") {
        Vue.notify({
          group: "toast",
          type: "error",
          title: "Veuillez remplir le formulaire",
        });
      } else if (this.metadata.postal == "") {
        Vue.notify({
          group: "toast",
          type: "error",
          title: "Veuillez remplir le formulaire",
        });
      } else if (this.metadata.city == "") {
        Vue.notify({
          group: "toast",
          type: "error",
          title: "Veuillez remplir le formulaire",
        });
      } else if (this.metadata.email == "") {
        Vue.notify({
          group: "toast",
          type: "error",
          title: "Veuillez remplir le formulaire",
        });
      } else if (this.metadata.phone_number == "") {
        Vue.notify({
          group: "toast",
          type: "error",
          title: "Veuillez remplir le formulaire",
        });
      } else if (!isValidEmail(this.metadata.email)) {
        Vue.notify({
          group: "toast",
          type: "error",
          title: "Email invalide",
        });
      } else {
        this.order(lineItems);
        this.$gtag.event("begin_checkout", {
          item_list_id: item_list_id,
          item_list_name: item_list_name,
        });
      }
    },
    order(lineItems) {
      fetch(this.$API + "/checkout", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          line_items: lineItems,
          metadata: this.metadata,
          delivery: this.metadata.delivery,
        }),
      })
        .then((r) => r.json())
        .then((r) => {
          console.log(r);
          this.$stripe.then((stripe) => {
            stripe.redirectToCheckout({
              sessionId: r.id,
            });
          });
        });
    },
  },
  beforeMount() {
    fetch(this.$API + "/checkout/shippingtax/" + this.totalWeight)
      .then((r) => r.json())
      .then((r) => {
        this.shippingTax = parseInt(r.price);
      });
    this.cartsection = true;
    this.loading = false;
  },
};
</script>

<style scoped>
.cart-section,
.checkout-section {
  padding: 100px 0;
  color: white;
}

.cart-section .title {
  font-size: 2rem;
}

.case {
  padding: 20px;
}

.validate-cart {
  margin: 3rem;
}

.total {
  border-top: solid 1px wheat;
}

.checkout-section form {
  display: grid;
  justify-items: center;
  align-items: center;
  text-align: left;
}

.checkout-section form * {
  color: white;
  width: 50%;
  font-size: 1.2rem;
}

.check {
  width: auto !important;
}

.checkout-section form div {
  font-size: 15px;
}
</style>
